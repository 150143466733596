var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "students-list column" },
    [
      _vm.classroom.students.length > 0
        ? _c(
            "div",
            _vm._l(_vm.classroom.students, function(student) {
              return _c("student", {
                key: student.id,
                attrs: { student: student }
              })
            })
          )
        : _c("div", { staticClass: "no-content row" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("noContent")))])
          ]),
      _c(
        "button",
        {
          staticClass: "btn round primary",
          on: {
            click: function($event) {
              _vm.showAddStudentModal = true
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("addStudent")))]
      ),
      _vm.showAddStudentModal
        ? _c(
            "modal",
            {
              directives: [
                {
                  name: "closable",
                  rawName: "v-closable",
                  value: { exclude: [""], handler: "closeModal" },
                  expression: "{exclude: [''], handler: 'closeModal'}"
                }
              ],
              attrs: { showButtons: false },
              on: {
                close: function($event) {
                  _vm.showAddStudentModal = false
                }
              }
            },
            [
              _c(
                "tab",
                {
                  ref: "newUserTab",
                  attrs: { pages: _vm.pages, default: _vm.pages[0] },
                  on: { tabChanged: _vm.tabChanged }
                },
                [
                  _vm.selectedPage
                    ? _c(_vm.selectedPage.component, {
                        tag: "component",
                        on: {
                          closeModal: function($event) {
                            _vm.showAddStudentModal = false
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }