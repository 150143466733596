var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "unit-preview column",
      class: { "item-expanded": !_vm.isCollapsed },
      style: { height: _vm.height }
    },
    [
      _c("div", { staticClass: "unit-details flex-container" }, [
        _c("img", {
          attrs: { src: _vm.expandIcon },
          on: { click: _vm.expandContent }
        }),
        _c("span", { staticClass: "legend" }, [
          _vm._v(_vm._s(_vm.$t("unit")) + " " + _vm._s(_vm.unit.number))
        ]),
        _c("span", [_vm._v(_vm._s(_vm.unit.name))])
      ]),
      !_vm.isCollapsed
        ? _c("div", { staticClass: "column" }, [
            _vm.unit.lessons
              ? _c(
                  "div",
                  { staticClass: "content-list" },
                  _vm._l(_vm.unit.lessons, function(lesson) {
                    return _c(
                      "div",
                      {
                        key: "lesson" + lesson.id + lesson.number,
                        staticClass: "list-item row",
                        on: {
                          click: function($event) {
                            _vm.previewLesson(lesson)
                          }
                        }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(lesson.number))]),
                        _c("span", [
                          _vm._v(_vm._s(lesson.name || _vm.$t("lesson")))
                        ])
                      ]
                    )
                  })
                )
              : _vm._e(),
            _c("span", { staticClass: "other-content" }, [
              _vm._v(_vm._s(_vm.$t("otherContentTag")))
            ]),
            _c(
              "div",
              { staticClass: "content-list" },
              [
                _vm._l(_vm.biographies, function(biography) {
                  return _c(
                    "div",
                    {
                      key: "biogrphy:" + biography.id,
                      staticClass: "list-item row",
                      on: {
                        click: function($event) {
                          _vm.previewBiography(biography)
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(biography.name || "Biography"))
                      ])
                    ]
                  )
                }),
                _vm._l(_vm.unitStories, function(story) {
                  return _c(
                    "div",
                    {
                      key: "story:" + story.id,
                      staticClass: "list-item row",
                      on: {
                        click: function($event) {
                          _vm.previewStory(story)
                        }
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(story.name || "Unit Story"))])]
                  )
                }),
                _vm._l(_vm.assessments, function(assessment) {
                  return _c(
                    "div",
                    {
                      key: "assessment:" + assessment.id,
                      staticClass: "list-item row",
                      on: {
                        click: function($event) {
                          _vm.previewAssessment(assessment)
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("assessment")) +
                            " - " +
                            _vm._s(_vm.$t("lesson")) +
                            "\n          " +
                            _vm._s(assessment.number)
                        )
                      ])
                    ]
                  )
                }),
                _vm.unit.hasTimeLine
                  ? _c(
                      "div",
                      {
                        staticClass: "list-item row",
                        on: { click: _vm.previewTimeline }
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.$t("timeline")))])]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    key: "teacher page unit:" + _vm.unit.id,
                    staticClass: "list-item row",
                    on: {
                      click: function($event) {
                        _vm.previewTeacherPage(_vm.unit)
                      }
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("teacherPage")) +
                          " - " +
                          _vm._s(_vm.$t("unit")) +
                          " " +
                          _vm._s(_vm.unit.number)
                      )
                    ])
                  ]
                ),
                _vm._l(_vm.teacherPageLessons, function(lesson) {
                  return _c(
                    "div",
                    {
                      key: "teacher page:" + lesson.id,
                      staticClass: "list-item row",
                      on: {
                        click: function($event) {
                          _vm.previewTeacherPage(lesson)
                        }
                      }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("teacherPage")) +
                            " - " +
                            _vm._s(_vm.$t("lesson")) +
                            "\n          " +
                            _vm._s(lesson.number)
                        )
                      ])
                    ]
                  )
                })
              ],
              2
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }