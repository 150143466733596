var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "standards-report" }, [
    _c("div", { staticClass: "report-container custom-scrollbar" }, [
      !_vm.report
        ? _c("span", { staticClass: "centered" }, [
            _vm._v(_vm._s(_vm.$t("noContentToDisplayMessage")))
          ])
        : _c("div", { staticClass: "report-body row ml-0 mr-0" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c("span", { staticClass: "students-header" }, [
                  _vm._v(_vm._s(_vm.$t("student")))
                ]),
                _vm._l(_vm.students, function(student, index) {
                  return _c(
                    "span",
                    { key: index, staticClass: "student-name" },
                    [_vm._v(_vm._s(" " + student))]
                  )
                })
              ],
              2
            ),
            _c("div", { staticClass: "table-container column report-table" }, [
              _c(
                "table",
                [
                  _c(
                    "thead",
                    _vm._l(_vm.teks, function(tek) {
                      return _c("th", { key: tek }, [_vm._v(_vm._s(tek))])
                    })
                  ),
                  _vm._l(_vm.teksByStudent, function(teks, index) {
                    return _c(
                      "tr",
                      { key: index },
                      _vm._l(teks, function(tek, tekIndex) {
                        return _c(
                          "td",
                          {
                            key: index + " - " + tekIndex,
                            style: _vm.teksStatus(tek)
                          },
                          [_vm._v(_vm._s(_vm._f("round")(tek)) + "%")]
                        )
                      })
                    )
                  }),
                  _vm.teks.length < 1
                    ? _c(
                        "div",
                        { staticClass: " centered no-content row legend" },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("noContent")))])]
                      )
                    : _vm._e()
                ],
                2
              )
            ])
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }