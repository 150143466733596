<template>
    <div class="reset-student-password-container">
        <div class="">
            <div class="col-12">
                <div class="header row">
                    <span >{{$t('resetPasswordButtonText')}}</span>
                    <span class="close-icon" @click="hideModal">&times;</span>
                </div>
            </div>
        </div>
        <form action="send" @submit.prevent="resetPassword" method="post" :autocomplete="'off'">
        <div class="password-fields column">
            <label for="password" 
                :class="[{visibility: password == '', errorMessage: !isValidPassword}]">
                {{`${isValidPassword ? $t("signPasswordLabelText") 
                                        : $t("invalidPassword")}`}}</label>
            <input type="password" name="password" v-model="password" 
                    :placeholder='$t("signPasswordLabelText")'/>

            <label for="confirmPassword" 
                :class="[{visibility: confirmPassword == '', errorMessage: !isValidConfirmPassword}]">
                {{`${isValidConfirmPassword ? $t("confirmPasswordLabelText") 
                                            : $t("notSamePassword")}`}}</label>
            <input type="password" name="confirmPassword" v-model="confirmPassword" 
                    :placeholder='$t("confirmPasswordLabelText")'/>
        </div>

        <div class="col-12 ">
            <div class="buttons row">
                <button @click="cleanFormFields" class="btn secondary">{{$t('cleanButtonText')}}</button>
                <input type="submit" class="btn primary" :class="[{'disabled': !validForm}]" :value="$t('resetStudentPasswordButtonText')"
                :disabled="!validForm"/>
            </div>
        </div>        
        </form>
    </div>
</template>

<script>

import ValidationHelper from "@/utils/ValidationHelper.js";

export default {
    name: "ResetStudentPassword",
    components: {
        ValidationHelper
    },
    props: {
        student: Object
    },
    data: function(){
        return {
            password: "",
            confirmPassword: ""
        };
    },
    computed: {
        validForm(){
            return this.isValidPassword
                    && this.isValidConfirmPassword;
        },
        isValidPassword(){
            return this.password != "" 
                    && ValidationHelper.validSimplePassword(this.password);
        },
        isValidConfirmPassword(){
            return this.password === this.confirmPassword;
        }
    },
    methods: {
        resetPassword(){
            if(!this.validForm){
                this.$store.commit('addSnackBarNotification', {
                        message: this.$t("mustFillFields"), 
                        dismissable: false
                    });

                return;
            }

            this.$store.dispatch("resetStudentPassword", {
                id: this.student.id, 
                password: this.password, 
            }).then(() => {
                this.hideModal();
                this.$store.commit('addSnackBarNotification', {
                        message: this.$t("successfulPasswordResetMessage"), 
                        dismissable: false
                    });
            }, error => {
                this.$store.commit('addSnackBarNotification', {
                        message: this.$t(error), 
                        dismissable: false
                    });
            });
        },
        cleanFormFields(e){
            e.preventDefault();
            this.password = "";
            this.confirmPassword = "";
        },
        hideModal(){
            this.$emit("close");
        }
    }
}

</script>

<style lang="less" scoped>

.reset-student-password-container{
    margin: 0px auto;
    width: 329px;
    padding: 24px;

    .header{
        // width: 100%;
        background-color: #EAEBFF;
        min-height: 24px;
        span{
            align-self: center;
            margin-left: 14px;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            white-space: normal;
            text-transform: uppercase;
        }
        .close-icon{
            margin-right: 16px;
            margin-left: auto;
            justify-self: flex-end;
            align-self: center;
            font-size: 2em;
            font-weight: 400;
            color: rgba(10, 21, 84, 0.6);
            &:hover{
                cursor: pointer;
            }
        }
        
    }
    .password-fields{
        width: 100%;
        margin-top: 30px;
        margin-bottom: 26px;

        label{
            height: 24px;
            color: rgba(0,0,0,0.7);
            font-family: Roboto;
            font-size: 12px;
            line-height: 12px;

            margin-bottom: 6px;
        }

        .errorMessage{
            color: red;
        }

        label.visibility{
            visibility: hidden;
        }

        input{
            width: 100%;
            min-height: 24px;
            max-height: 24px;
            border: none;
            border-bottom: 1px solid #9E9E9E;
            padding-bottom: 3px;

            margin-bottom: 23px;

            color: rgba(0,0,0,0.54);
            font-family: Roboto;
            font-size: 16px;
            line-height: 24px;
            
            &:hover{
                cursor: auto;
            }

            &:focus{
                outline: none;
            }
        }
    }

    .buttons{
        // width: 100%;
        margin-bottom: 0;
        .btn{
            &.secondary{
                margin-left: auto;
                margin-right: 8px;
            }
            width: 90px;
        }
    }
}
</style>

