<template>
    <div class="standards-report">
        <div class="report-container custom-scrollbar">
            <span v-if="!report" class="centered">{{$t('noContentToDisplayMessage')}}</span>
            <div v-else class="report-body row ml-0 mr-0">
                <div class="column">
                    <span class="students-header">{{$t('student')}}</span>
                    <span class="student-name" v-for="(student, index) in students"
                     :key="index">{{' '+ student}}</span>
                </div>
                <div class="table-container column report-table">
                    <table>
                        <thead><th v-for="tek in teks" :key="tek">{{tek}}</th></thead>
                        <tr v-for="(teks, index) in teksByStudent" :key="index">
                            <td v-for="(tek, tekIndex) in teks" :key="`${index} - ${tekIndex}`"
                            :style="teksStatus(tek)"
                            >{{tek | round}}%</td>
                        </tr>
                            <div v-if="teks.length < 1" class=" centered no-content row legend">
                                <span>{{$t("noContent")}}</span>
                            </div>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'StandardsReport', 
    computed:{
        report(){
            return this.$store.state.standardsReport
        }, 
        classroom(){
            return this.$store.state.selectedClassroom
        }, 
        students(){
            if(!this.report)
                return []
            return this.report.teksStudentScores.map(e =>{
                return `${e.student.firstName} ${e.student.lastName}`
            })
        }, 
        teks(){
            return this.report.teks
        },
        teksByStudent(){
            if(!this.report)
                return []
            let teksbyStudent= [];
            this.report.teksStudentScores.map(e =>{
                teksbyStudent.push(e.tekValues)
            })
            return teksbyStudent
        }, 
    },
    methods:{ 
        onlyUnique(value, index, self){ 
            return self.indexOf(value) === index;
        }, 
        teksStatus(value){
            let color = {
                backgroundColor: 'unset',
            }
            if(value >= 82){
                color.backgroundColor = '#a0f2a4';
            }
            if(value >= 73 && value < 82)
                color.backgroundColor = '#ffc460';
            if(value > 0  && value < 73)
                color.backgroundColor = '#ff8989';
            return color
        }
    }, 
    mounted(){
        this.$appInsights.startTrackPage("Standards Report");
        this.$store.commit("setAnalyzedManagementSection", "Standards Report");
        this.isLoadingInformation = true;
		this.$store.dispatch('loadStandardsReport', this.classroom.id)
                .finally(()=>{
                    this.isLoadingInformation = false;
                })
    },  
    beforeDestroy(){
        this.$store.commit("setAnalyzedManagementSection", "");
        this.$appInsights.stopTrackPage("Standards Report");
        this.$store.commit("setClassroomPerformance", []);
    }
}
</script>
<style lang="less" scoped>
.report-container{
	min-width: 680px;
    max-width: 680px;
    min-height: 400px;
    padding: 16px;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: auto;
    position: relative;
    padding-top: 0;

    .column:first-of-type{
        position: relative;
    }

    &.custom-scrollbar{
        overflow-y: auto;
    }

    .students-header{
        height: 56px;
        width: 100%;
        color: #0A1554;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 56px;
        text-transform: uppercase;
        position: sticky;
        top:0;
        background: #fff;
    }

    .student-name{
        height: 56px;
        min-height: 56px;
        color: rgba(0,0,0,0.6);
        background-color: #fff;
        font-family: Roboto;
        font-size: 16px;
        line-height: 56px;
        text-transform: capitalize;
        min-width: 128px;
        &:nth-child(even){
            background-color: #F6F7FA;
        }
    }
    .table-container{
        position: relative;
        overflow-y: auto;
        &::-webkit-scrollbar {
        width: 14px;
        height: 14px;
        }
        &::-webkit-scrollbar-thumb {
            height: 6px;
            border: 4px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 7px;
            background-color: rgba(0, 0, 0, 0.15);
            box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
        }
        &::-webkit-scrollbar-button {
            width: 0;
            height: 0;
            display: none;
        }
        &::-webkit-scrollbar-corner {
            background-color: transparent;
        }
    }
    
    table{
        border-spacing: 0px;
        border-collapse: unset;
        position: relative;
    }
    thead{
        background: #fff;
        width: 100%;
        min-height: 56px;
        th{
            color: #0A1554;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            text-transform: uppercase;
            text-align: center;
            align-self: center;
            max-width: 124px;
            min-width: 124px;
            min-height: 56px;
            height: 56px;
        }

    }
    tr{
        min-height: 56px;
        max-height: 56px;
        height: 56px;

        &:nth-child(even){
            background-color: #F6F7FA;
        }
        td{
            border-left-width: 0;
            min-height: 56px;
            height: 56px;
            min-width: 124px;
            max-width: 124px;
            width: 124px;
            color: rgb(0,0,0);
            font-family: Roboto;
            font-weight: 300;
            font-size: 14px;
            letter-spacing: 0.5px;
            line-height: 28px;
            text-transform: capitalize;
            text-align: center;
            padding: 0;
        }

    }
    .report-table {
        width: calc(100% - 152px);
    }
}
</style>


