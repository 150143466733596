var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.classroom
    ? _c(
        "div",
        { staticClass: "classroom-details row" },
        [
          _c(
            "div",
            { staticClass: "column custom-scrollbar" },
            [
              _c("div", { staticClass: "classroom-details-container" }, [
                _c(
                  "div",
                  { staticClass: "row ml-0 mr-0" },
                  [
                    !_vm.editMode
                      ? _c("span", { staticClass: "legend" }, [
                          _vm._v(_vm._s(_vm.name))
                        ])
                      : [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.name,
                                expression: "name"
                              }
                            ],
                            staticClass: "classroom-name",
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("titlePlaceHolder")
                            },
                            domProps: { value: _vm.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.name = $event.target.value
                              }
                            }
                          })
                        ],
                    !_vm.editMode
                      ? _c(
                          "button",
                          {
                            staticClass: "btn secondary",
                            on: { click: _vm.changeEditMode }
                          },
                          [_vm._v(_vm._s(_vm.$t("edit")))]
                        )
                      : [
                          _c(
                            "button",
                            {
                              staticClass: "btn secondary",
                              on: { click: _vm.changeEditMode }
                            },
                            [_vm._v(_vm._s(_vm.$t("cancel")))]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn primary",
                              on: { click: _vm.updateClassroom }
                            },
                            [_vm._v(_vm._s(_vm.$t("saveChangesTag")))]
                          )
                        ]
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "row ml-0 mr-0" },
                  [
                    !_vm.editMode
                      ? _c("div", { staticClass: "mr-2" }, [
                          _c("span", [_vm._v(_vm._s(_vm.$t("classPeriod")))]),
                          _c("span", [_vm._v(_vm._s(_vm.timePeriod))])
                        ])
                      : [
                          _c(
                            "div",
                            { staticClass: "row editable-dates ml-0 mr-2" },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("classPeriod")))
                              ]),
                              _c("datepicker", {
                                on: { selected: _vm.updateStartDate },
                                model: {
                                  value: _vm.StartDate,
                                  callback: function($$v) {
                                    _vm.StartDate = $$v
                                  },
                                  expression: "StartDate"
                                }
                              }),
                              _c("span", [_vm._v(" - ")]),
                              _c("datepicker", {
                                on: { selected: _vm.updateEndDate },
                                model: {
                                  value: _vm.EndDate,
                                  callback: function($$v) {
                                    _vm.EndDate = $$v
                                  },
                                  expression: "EndDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                    _c("div", { staticClass: "row ml-0" }, [
                      _c("span", [_vm._v(_vm._s(_vm.$t("teacher")))]),
                      _c("span", [_vm._v(_vm._s(_vm.teacherName))])
                    ])
                  ],
                  2
                ),
                _c("div", { staticClass: "row ml-0 mr-0" }, [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("studentsNumber")))]),
                    _vm.classroom
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.classroom.students.length +
                                " " +
                                _vm.$t("students")
                            )
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm.classroomPerformance.length > 0
                    ? _c("div", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("averageScore")) + ":")
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("round")(_vm.averageScore)))
                        ])
                      ])
                    : _vm._e()
                ])
              ]),
              _c(
                "tab",
                {
                  attrs: { pages: _vm.pages, default: _vm.defaultTabPage },
                  on: { tabChanged: _vm.tabChanged }
                },
                [
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _vm.displayExpandButton
                        ? _c("div", { staticClass: "row ml-0 mr-0" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn secondary buttoms",
                                on: {
                                  click: function($event) {
                                    _vm.isReportExpanded = true
                                  }
                                }
                              },
                              [_vm._v("Expand")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn secondary buttoms",
                                on: { click: _vm.exportReport }
                              },
                              [_vm._v("Export")]
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [
                          _vm.selectedPage
                            ? _c(_vm.selectedPage.component, {
                                tag: "component"
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm.isReportExpanded
                ? _c(
                    "modal",
                    {
                      staticClass: "expanded-report",
                      attrs: {
                        showCancelButton: false,
                        "click-handler": _vm.hideReport
                      },
                      on: {
                        close: function($event) {
                          _vm.isReportExpanded = false
                        }
                      }
                    },
                    [
                      _vm.selectedPage
                        ? _c(_vm.selectedPage.component, { tag: "component" })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("class-room-panel")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }