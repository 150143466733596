const youtubeRegex= /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$$/i
const videoCDNRegex= /(^https:\/\/)(.+)(\w\.(mp4|ogg|webm)$)/i
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const passwordRegex = /^([\w.-]+)@([\w-]+)((\.(\w){2,3})+)*$/
const usernameRegex = /^[a-zA-Z0-9]{8,15}$/;

export default {
    isYoutubeVideoUrl(url){
        return youtubeRegex.test(url)
    }, 
    isHexColorValid(color){
        return !/^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(color)
    },
    isVideoUrlValid(url){
        let isCompletelyValidUrl = url == "" || youtubeRegex.test(url);
        let isCompletelyInvalidUrl = !isCompletelyValidUrl && !videoCDNRegex.test(url)

        if(isCompletelyValidUrl || isCompletelyInvalidUrl)
            return isCompletelyValidUrl
        
            var video = document.createElement("video");
        video.src = url
        try {
        
            video.play()
                 .then(()=> video.pause())
            return true
        } catch(e){
            return false
        }
    }, 
    validateEmail(email) {
        return emailRegex.test(email);
    }, 
    validatePassword(password){
        return passwordRegex.test(password)
    },
    validUsername(username){
        return usernameRegex.test(username);
    },
    validSimplePassword(password){
        let cleanedPassword = password.trim();

        return cleanedPassword.length >= 8 && cleanedPassword.length <= 15;  
    }
}
