var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "students-batch" }, [
    _c(
      "form",
      {
        staticClass: "column",
        attrs: { action: "send", method: "post", autocomplete: "off" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.validateForm($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "file-upload column" },
          [
            !_vm.fileName
              ? [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.$t("cvsFile"),
                          expression: "$t('cvsFile')"
                        }
                      ],
                      staticClass: "upload-btn-wrapper"
                    },
                    [
                      _c("button", { staticClass: "btn round secondary" }, [
                        _vm._v(_vm._s(_vm.$t("studentsBatch")))
                      ]),
                      _c("input", {
                        attrs: { type: "file", accept: ".csv" },
                        on: { change: _vm.onFileChange }
                      })
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "batch-link",
                      attrs: { href: _vm.batchLink }
                    },
                    [_vm._v("Download BULK STUDENT ROSTER (CSV) here.")]
                  )
                ]
              : [
                  _c("span", [_vm._v(_vm._s(_vm.fileName))]),
                  _c("img", {
                    staticClass: "delete-icon",
                    attrs: { src: _vm.deleteIcon },
                    on: { click: _vm.removeFile }
                  })
                ]
          ],
          2
        ),
        _c("div", { staticClass: "row buttons" }, [
          _c("input", {
            staticClass: "btn secondary",
            attrs: { type: "text" },
            domProps: { value: _vm.$t("cancel") },
            on: {
              click: function($event) {
                _vm.$emit("closeModal")
              }
            }
          }),
          _c("input", {
            staticClass: "btn primary",
            attrs: { type: "submit" },
            domProps: { value: _vm.$t("submit") }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }