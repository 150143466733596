<template>
    <div class="new-student">
        <form action="send" @submit.prevent="validateForm" method="post" :autocomplete="'off'">
        
		<div class="column">
			<label for="username" 
                :style="{opacity: username ? 1 : 0 }"
                :class="[{errorMessage: !isValidUsername}]">
                {{`${isValidUsername ? $t("username") 
                                            : isUserNameAvailable ? 
                                                $t("invalidUsername")
                                                    : $t("usernameInUse")}`}}</label>
            <div>
                <input type="text" name="username" :placeholder="$t('userNameLabelText')" autocomplete="false"
                v-on:blur="checkUsernameAvailabality"  v-on:focus="focusEventHandler" v-model="username">
                <i v-if="username" class="circle" :class="circleStatus"> 
                    <div :class="IconStatus"></div>
                </i>
            </div>
		</div>
		<div class="column">
			<label for="password" 
                :style="{opacity: password ? 1 : 0 }"
                :class="[{errorMessage: !isValidPassword}]">
                {{`${isValidPassword ? $t("signPasswordLabelText") 
                                            : $t("shortInvalidPassword")}`}}</label> 
            <input type="password" v-model="password" :placeholder="$t('signPasswordLabelText')">
		</div>
		<div class="column">
			<label  :style="{opacity: firstName ? 1 : 0 }">{{$t('firstName')}}:</label>
            <input type="text" v-model="firstName"  :placeholder="$t('firstName')">
		</div>
        <div class="column">
			<label :style="{opacity: middleName ? 1 : 0 }">{{$t('middleName')}}:</label>
            <input type="text" v-model="middleName" :placeholder="$t('middleName')">    
		</div>
		<div class="column">
			<label :style="{opacity: lastName ? 1 : 0 }">{{$t('lastName')}}:</label>
            <input type="text" v-model="lastName" :placeholder="$t('lastName')">
		</div>
        <div class="column">
			<label :style="{opacity: secondLastName ? 1 : 0 }">{{$t('secondLastName')}}:</label>
            <input type="text" v-model="secondLastName" :placeholder="$t('secondLastName')">
		</div>
		<div class="column newStudent-datePicker">
            <label for="text">{{$t('birthDate')}}:</label>
            <datepicker v-on:selected="updateBirthDate" v-model="birthDate"></datepicker>    
        </div>
        <div class="buttons">
            <div class="col-12">
                <div class="row mr-0 ml-0">
                    <input type="text" class="btn secondary" @click="$emit('closeModal')" :value="$t('cancel')">
                    <input type="submit" class="btn primary" :value="$t('submit')">
                </div>
            </div>
        </div>
        </form>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import ValidationHelper from "@/utils/ValidationHelper.js";

export default {
    name: 'NewStudent',
    components: {
        Datepicker,
        ValidationHelper
    },
    data: function(){
        return {
            birthDate: moment().toDate(), 
            isUserNameAvailable: true,
        }
    },
    computed:{
        classroom(){
            return this.$store.state.selectedClassroom
        }, 
        student(){
            return this.$store.state.newStudent
        },
        username:{
            get(){
                return this.student.username || "";
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'username')
            }
        },
        password:{
            get(){
                return this.student.password || "";
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'password')
            }
        },
        firstName:{
            get(){
                return this.student.firstName
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'firstName')
            }
        },
        lastName:{
            get(){
                return this.student.lastName
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'lastName')
            }
        }, 
        middleName:{
            get(){
                return this.student.middleName
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'middleName')
            }
        }, 
        secondLastName:{
            get(){
                return this.student.secondLastName
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'secondLastName')
            }
        }, 
        circleStatus(){
            return this.isValidUsername ? 'completed' : 'failed'
        },
        IconStatus(){
            return this.isValidUsername ? 'check' : 'cross'
        },
        isValidUsername(){
            return ValidationHelper.validUsername(this.username)
                    && this.isUserNameAvailable;
        },
        isValidPassword(){
            return ValidationHelper.validSimplePassword(this.password);
        }
    },
    methods:{
        updateBirthDate(e){
            e = new Date(e).toLocaleDateString()
            this.setComponentPropertyValue(e, 'birthDate')
        }, 
        setComponentPropertyValue(value, propertyName){
            this.$store.commit('setBuilderComponentPropertyValue',
                    {component: this.student, propertyName: propertyName, value: value})
        },
        checkUsernameAvailabality(){
            if(!this.username)
                return;

            this.$store.dispatch("checkUsernameAvailabality", this.username)
                        .then(() => { this.isUserNameAvailable = false; })
                        .catch(() => { this.isUserNameAvailable = true; })
                        .finally(() => {
                            this.setComponentPropertyValue(this.isUserNameAvailable, 
                                                            "isUsernameAvailable");
                        });
        },
        focusEventHandler(){
            this.$store.dispatch("setDealerPromise");
            this.isUserNameAvailable = true;
        }, 
        validateForm()
        {
			let _newStudent = this.$store.state.newStudent;
			let _birthDate = null;

			if(!_newStudent.username || !_newStudent.isUsernameAvailable
				|| !ValidationHelper.validUsername(_newStudent.username)
				|| !ValidationHelper.validSimplePassword(_newStudent.password)
				|| !_newStudent.firstName || !_newStudent.lastName 
				|| !_newStudent.classRoomId || !(_birthDate = moment(_newStudent.birthDate)).isValid()){
				this.$store.commit('addSnackBarNotification', {
                    message: "Some fields are still empty or have invalid values", 
                    dismissable:true
				});
				
                return;
			}

			this.$store.state.dealerPromise.promise
					.then(() => {
						this.showAddStudentModal = false;
						this.$store.dispatch('addStudent', {
							student: { 
								...this.$store.state.newStudent,
								birthDate: _birthDate.toDate()
							}, 
							classroom:  this.classroom
                        });
                        this.$emit('closeModal')
					}, () => {
						this.$store.commit('addSnackBarNotification', {
							message: "Username already in use", 
							dismissable:true
						});
                    });
            return
        }
    }, 
    created(){
        this.setComponentPropertyValue(moment().toDate(), 'birthDate')
        this.setComponentPropertyValue(this.classroom.id, 'classRoomId')
    },
    beforeDestroy(){
        for(let key of Object.keys(this.student)){
            this.setComponentPropertyValue(undefined, key)
        }

        this.$store.commit("setDealerPromise", null);
    }
}
</script>
<style lang="less" scoped>
.new-student{
    // margin-top: -32px !important;
    width: 100%;
    height: auto;
	margin: 0 auto;
	position: relative;
	padding:0 0 16px 0;
    box-sizing: border-box;
    >.row{
        margin-top: 16px;
        .btn:first-child{
            margin-right: 8px;
        }
    }
    
	form > .column{
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
        width: 329px;
        height: 44px;
        .circle{
            margin-left: 8px;
            align-self: center;
        }
        .legend{
            margin: auto;
        }
    }
    .column{
        label{
            height: 14px;
            color: rgba(0,0,0,0.7);
            font-family: Roboto;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 6px;

            &.errorMessage{
                color: red;
                font-size: 10px;
            }
        }
        input{
            width: 100%;
            border: none;
            max-height: 24;
            border-bottom: 1px solid #9E9E9E;
            height: 24px;
            min-height: 24px;
            font-family: 'Roboto' sans-serif;
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            padding-bottom: 3px;
            color: rgba(0,0,0,0.87);
            &:focus{
                outline: none;
            }
        }
        &.newStudent-datePicker {
            .vdp-datepicker__calendar {
                bottom: 0;
            }
        }

    }

    .buttons{
        margin-top: 24px;
        .btn{
            max-width: 90px;
        }
        .secondary{
            margin-left: auto;
            margin-right: 8px;
        }
    }
}
</style>
<style lang="less">
.new-student{
    .column{
        &.newStudent-datePicker {
            .vdp-datepicker__calendar {
                bottom: 40px;
            }
        }
    }
}
</style>



