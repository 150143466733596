var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.student
    ? _c(
        "div",
        { staticClass: "student-item row ml-0 mr-0" },
        [
          _c("span", { on: { click: _vm.goToTeacherFeedBack } }, [
            _vm._v(
              _vm._s(_vm.student.firstName[0]) + _vm._s(_vm.student.lastName[0])
            )
          ]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.student.firstName) +
                " " +
                _vm._s(_vm.student.lastName) +
                " (" +
                _vm._s(_vm.student.userName) +
                ")"
            )
          ]),
          _c(
            "button",
            {
              staticClass: "btn secondary",
              on: { click: _vm.displayResetStudentPasswordModal }
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("resetPasswordButtonText").toUpperCase()) +
                  "\n        "
              )
            ]
          ),
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("Text")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.studentLanguage,
                    expression: "studentLanguage"
                  }
                ],
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.studentLanguage = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.languages, function(language, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: language.key } },
                  [_vm._v(_vm._s(language.text.toUpperCase()))]
                )
              })
            )
          ]),
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("Audio")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.audioLanguage,
                    expression: "audioLanguage"
                  }
                ],
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.audioLanguage = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.languages, function(language, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: language.key } },
                  [_vm._v(_vm._s(language.text.toUpperCase()))]
                )
              })
            )
          ]),
          _c("img", {
            staticClass: "delete-icon",
            attrs: { src: _vm.deleteIcon },
            on: {
              click: function($event) {
                _vm.showRemoveStudentModal = true
              }
            }
          }),
          _vm.showRemoveStudentModal
            ? _c(
                "modal",
                {
                  attrs: {
                    "click-handler": _vm.removeStudent,
                    "text-button": "Remove"
                  },
                  on: {
                    close: function($event) {
                      _vm.showRemoveStudentModal = false
                    }
                  }
                },
                [
                  _c("div", { staticClass: "modal-message row" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("removeStudentMessage")))])
                  ])
                ]
              )
            : _vm._e(),
          _vm.showResetStudentPasswordModal
            ? _c(
                "modal",
                {
                  attrs: {
                    clickOutSideModalHandler: _vm.hideModal,
                    showButtons: false
                  }
                },
                [
                  _c("reset-student-password", {
                    attrs: { student: _vm.student },
                    on: { close: _vm.hideModal }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }