<template>
    <div class="classroom-performance-container">
        <div class="classroom-performance-body column">
            <table>
                <thead>
                    <tr>
                        <th>{{$t("student")}}</th>
                        <th>{{$t("completedUnits")}}</th>
                        <th>{{$t("completedLessons")}}</th>
                        <th>{{$t("completionLevel")}}</th>
                        <th>{{$t("generalScore")}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in classroomPerformance"
                        :key="index">
                        <td>{{`${item.student.firstName} ${item.student.lastName}`}}</td>
                        <td>{{item.unitsCompleted}}</td>
                        <td>{{item.lessonsCompleted}}</td>
                        <td>{{item.completionlevel | round}}%</td>
                        <td>{{item.generalScore | round}}%</td>
                    </tr>
                    <tr v-if="classroomPerformance.length == 0" class="no-content">
                        <td colspan="5">{{$t("noContent")}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "ClassroomPerformance",
    computed: {
        classroomPerformance(){
            return this.$store.state.classroomPerformance;
        },
        selectedClassroom(){
            return this.$store.state.selectedClassroom;
        }
    },
    mounted(){
        this.$appInsights.startTrackPage("Classroom Performance");
        this.$store.commit("setAnalyzedManagementSection", "Classroom Performance");
        this.isLoadingInformation = true;
        this.$store.dispatch("getClassroomPerformance", this.selectedClassroom.id)
                    .then(null, error => {
                        this.$store.commit('addSnackBarNotification', {
                            message: this.$t(error), 
                                dismissable: false
                            });
                    })
                    .finally(()=>{
                        this.isLoadingInformation = false;
                    })
    },
    beforeDestroy(){
        this.$store.commit("setAnalyzedManagementSection", "");
        this.$appInsights.stopTrackPage("Classroom Performance");
        this.$store.commit("setClassroomPerformance", []);
    }
}
</script>

<style lang="less" scoped>

.classroom-performance-container{
    .classroom-performance-header{
        height: 56px;

        color: #0A1554;
        font-family: Montserrat;
        font-size: 19.64px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 56px;
    }

    .classroom-performance-body{
        min-height: 196px;
		box-sizing: border-box;
        padding: 16px;
        
        table{
            thead tr th{
                color: #0A1554;
                font-family: Roboto;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 2px;
                line-height: 16px;

                text-transform: uppercase;
                width: 17%;

                &:first-child{
                    text-align: left;
                    width: 32%;
                }
            }

            tbody tr{
                height: 56px;

                td{
                    color: rgba(0,0,0,0.6);
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 300;
                    letter-spacing: 0.25px;
                    line-height: 20px;

                    text-align: center;

                    &:first-child, &:last-child{
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        line-height: 28px;
                    }

                    &:first-child{
                        text-align: left;
                    }
                }

                &.no-content{
                    height: 300px;

                    td{
                        text-align: center;
                    }
                }
            }
        }
    }
}

</style>

