<template>
    <div class="students-batch">
        <form action="send" @submit.prevent="validateForm" method="post"
        class="column" :autocomplete="'off'">
            <div class="file-upload column">
                <template v-if="!fileName">
                    <div class="upload-btn-wrapper" v-tooltip="$t('cvsFile')" >
                        <button class="btn round secondary">{{$t('studentsBatch')}}</button>
                        <input type="file"  accept=".csv" @change="onFileChange">
                    </div>
                    <a class="batch-link" :href="batchLink">Download BULK STUDENT ROSTER (CSV) here.</a>
                </template>
                <template v-else>
                    <span>{{fileName}}</span>
                    <img :src="deleteIcon" class="delete-icon"  @click="removeFile"/>
                </template>
            </div>
            <div class="row buttons">
                <input type="text" class="btn secondary" @click="$emit('closeModal')" :value="$t('cancel')">
                <input type="submit" class="btn primary" :value="$t('submit')">
            </div>
        </form>
    </div>
</template>
<script>
export default {
    name: 'UploadStudentsBatch', 
    data: function(){
        return{
            fileName: '',
        }
    },
    computed:{
        classroom(){
            return this.$store.state.selectedClassroom
        }, 
        batchLink(){
            return process.env.VUE_APP_BATCH_LINK
        }
    }, 
    methods:{
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return; 
            this.fileName = files[0].name
            this.setComponentPropertyValue(files[0], 'file')
        }, 
        removeFile(){
            this.setComponentPropertyValue(undefined, 'file')
            this.fileName = undefined
        },        
        setComponentPropertyValue(value, propertyName){
            this.$store.commit('setBuilderComponentPropertyValue',
                    {component: this.classroom, propertyName: propertyName, value: value})
        }, 
        validateForm(){
            this.$store.commit('updateLoadingStatus', true)
            this.$store.dispatch('updateClassroom', this.$store.state.selectedClassroom)
                .finally(()=> {
                    this.$store.commit('updateLoadingStatus', false)
                })
            this.$emit('closeModal')
            return
        }
    }, 
    created(){
        this.setComponentPropertyValue(undefined, 'file')
    }, 
    beforeDestroy(){
        this.setComponentPropertyValue(undefined, 'file')
    }
}
</script>
<style lang="less" scoped>

.students-batch{
    height: 200px;
    width: 460px;
    form{
        height: 100%;
        .row:first-child{
            margin: auto;
        }
    }
    .file-upload{
        margin: auto;
        height: 80%;
    }
    .buttons{
        margin-top: auto;
        .btn{
            max-width: 90px;
        }
        .secondary{
            margin-left: auto;
            margin-right: 8px;
        }
    }

    .batch-link{
        font-size: 14px;
        margin-top: 16px;
        margin-bottom: auto;
        text-decoration: underline;
        font-style: italic;
    }
    
    .upload-btn-wrapper {
        margin: auto;
        position: relative;
        overflow: hidden;
        display: inline-block;
        margin-bottom: 16px;
        cursor: pointer;
        input{
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            height: 100%;
            width: 100%;
        }
    }
    img{
        cursor: pointer;
        margin-left: 8px;
    }

}

</style>

