var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tab column" }, [
    _c("div", { staticClass: "tab-pages" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "row ml-0 mr-0" },
          _vm._l(_vm.pages, function(page, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "tab-page",
                class: { "active-page": page === _vm.currentPage },
                on: {
                  click: function($event) {
                    _vm.changePage(page)
                  }
                }
              },
              [_c("span", [_vm._v(_vm._s(page.name))])]
            )
          })
        )
      ])
    ]),
    _c("div", { staticClass: "tab-body" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }