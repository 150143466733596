var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reset-student-password-container" }, [
    _c("div", {}, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "header row" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("resetPasswordButtonText")))]),
          _c(
            "span",
            { staticClass: "close-icon", on: { click: _vm.hideModal } },
            [_vm._v("×")]
          )
        ])
      ])
    ]),
    _c(
      "form",
      {
        attrs: { action: "send", method: "post", autocomplete: "off" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.resetPassword($event)
          }
        }
      },
      [
        _c("div", { staticClass: "password-fields column" }, [
          _c(
            "label",
            {
              class: [
                {
                  visibility: _vm.password == "",
                  errorMessage: !_vm.isValidPassword
                }
              ],
              attrs: { for: "password" }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    "" +
                      (_vm.isValidPassword
                        ? _vm.$t("signPasswordLabelText")
                        : _vm.$t("invalidPassword"))
                  )
              )
            ]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password"
              }
            ],
            attrs: {
              type: "password",
              name: "password",
              placeholder: _vm.$t("signPasswordLabelText")
            },
            domProps: { value: _vm.password },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              }
            }
          }),
          _c(
            "label",
            {
              class: [
                {
                  visibility: _vm.confirmPassword == "",
                  errorMessage: !_vm.isValidConfirmPassword
                }
              ],
              attrs: { for: "confirmPassword" }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    "" +
                      (_vm.isValidConfirmPassword
                        ? _vm.$t("confirmPasswordLabelText")
                        : _vm.$t("notSamePassword"))
                  )
              )
            ]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.confirmPassword,
                expression: "confirmPassword"
              }
            ],
            attrs: {
              type: "password",
              name: "confirmPassword",
              placeholder: _vm.$t("confirmPasswordLabelText")
            },
            domProps: { value: _vm.confirmPassword },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.confirmPassword = $event.target.value
              }
            }
          })
        ]),
        _c("div", { staticClass: "col-12 " }, [
          _c("div", { staticClass: "buttons row" }, [
            _c(
              "button",
              {
                staticClass: "btn secondary",
                on: { click: _vm.cleanFormFields }
              },
              [_vm._v(_vm._s(_vm.$t("cleanButtonText")))]
            ),
            _c("input", {
              staticClass: "btn primary",
              class: [{ disabled: !_vm.validForm }],
              attrs: { type: "submit", disabled: !_vm.validForm },
              domProps: { value: _vm.$t("resetStudentPasswordButtonText") }
            })
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }