<template>
    <div class="tab column">
        <div class="tab-pages">
            <div class="col-12">
                <div class="row ml-0 mr-0">
                    <div class="tab-page" v-for="(page, index) in pages" :key="index"
                    :class="{'active-page' :page === currentPage}"
                    @click="changePage(page)">
                        <span>{{page.name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-body">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Tab', 
    props:{
        pages:{
            type: Array, 
            required: true
        }, 
        default: {
            type: Object, 
            required: false, 
        }
    }, 
    data: function(){
        return{
            currentPage: ''
        }
    },
    methods:{
        changePage(page){
            this.currentPage =page
            this.$emit('tabChanged', this.currentPage)
        }
    }, 
    mounted(){
        if(this.default)
            this.changePage(this.default)
    }, 
    beforeDestroy(){
        this.currentPage = undefined
    }
}
</script>
<style lang="less" scoped>
.tab-pages{
    // margin-left: 16px;
}
.tab{
    min-width: 100%;
    margin-right: 32px;
    min-height: 100px;
    margin-right: 32px;
    background-color: #fff;
    box-shadow: var(--primary-shadow);
    &-page{
        min-height: 36px;
        padding: 10px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.6);
        border-right: 1px solid #ddd;

        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        // letter-spacing: 0.5px;
        line-height: 28px;
        text-transform: capitalize;
        font-weight: 400;

        cursor: pointer;
        &:hover{
            background-color: #eee;
            font-weight: 500;
        }

        &.active-page{
            color: #3A4DFD;
        }
    }
}
.tab .column{
    border-radius: 8px;
    margin-top: 0 !important;
}
.tab-body{
    // padding-left: 16px;
}
</style>