<template>
    <div class="program-preview">
        <div class="column">
            <unit v-for="unit in program" :key="unit.id" :unit="unit"/>
        </div>
    </div>
</template>
<script>
import UnitPreview from '@/components/ClassRoomManagement/UnitPreview.vue'
export default {
    name: 'ProgramPreview',
    components:{
        'unit': UnitPreview
    },
    computed:{
        program(){
            return this.$store.state.programPreview
        }
    },
    async mounted(){
        await this.$store.dispatch('loadProgramPreview')
    }
}
</script>
<style lang="less">
    .program-preview{
        min-height: 300px;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        >.column{
            min-height: 300px;
        }
    }
</style>