<template>
    <div class="classroom-panel panel flex-container">
        
    </div>
</template>
<script>
export default {
    name: 'ClassRoomPanel', 
    components:{
    },
    data(){
        return{
        }
    },
    computed:{
        
    }, 
    methods:{
    },
}
</script>
<style lang="less" scoped>
.classroom-panel{
    min-width: 264px;
    min-height: calc(100vh - 104px);
    margin-left: 15px;
    background: #F5F5F5;
}
</style>


