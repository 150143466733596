var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "program-preview" }, [
    _c(
      "div",
      { staticClass: "column" },
      _vm._l(_vm.program, function(unit) {
        return _c("unit", { key: unit.id, attrs: { unit: unit } })
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }