var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "classroom-performance-container" }, [
    _c("div", { staticClass: "classroom-performance-body column" }, [
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("student")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("completedUnits")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("completedLessons")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("completionLevel")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("generalScore")))])
          ])
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.classroomPerformance, function(item, index) {
              return _c("tr", { key: index }, [
                _c("td", [
                  _vm._v(
                    _vm._s(item.student.firstName + " " + item.student.lastName)
                  )
                ]),
                _c("td", [_vm._v(_vm._s(item.unitsCompleted))]),
                _c("td", [_vm._v(_vm._s(item.lessonsCompleted))]),
                _c("td", [
                  _vm._v(_vm._s(_vm._f("round")(item.completionlevel)) + "%")
                ]),
                _c("td", [
                  _vm._v(_vm._s(_vm._f("round")(item.generalScore)) + "%")
                ])
              ])
            }),
            _vm.classroomPerformance.length == 0
              ? _c("tr", { staticClass: "no-content" }, [
                  _c("td", { attrs: { colspan: "5" } }, [
                    _vm._v(_vm._s(_vm.$t("noContent")))
                  ])
                ])
              : _vm._e()
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }