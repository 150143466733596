<template>
	<div class="students-list column">
		<div v-if="classroom.students.length > 0">
			<student v-for="student in classroom.students" :key="student.id" :student="student" />
		</div>
		<div v-else class="no-content row">
			<span>{{$t("noContent")}}</span>
		</div>
		<button class="btn round primary" @click="showAddStudentModal = true">{{$t('addStudent')}}</button>
		<modal v-if="showAddStudentModal" @close="showAddStudentModal = false" :showButtons="false"
				v-closable="{exclude: [''], handler: 'closeModal'}">
				<tab :pages="pages" @tabChanged="tabChanged" ref="newUserTab"
					:default="pages[0]">
						<component v-if="selectedPage" :is="selectedPage.component"
							@closeModal="showAddStudentModal = false"
						/>
				</tab>
		</modal>
	</div>
</template>
<script>
import Modal from '@/common/Modal.vue'
import StudentListItem from './StudentListItem.vue'
import ValidationHelper from "@/utils/ValidationHelper.js";
import Tab from '@/common/Tab.vue'
import NewStudent from '@/components/ClassRoomManagement/NewStudent.vue'
import UploadStudentsBatch from '@/components/ClassRoomManagement/UploadStudentsBatch.vue'

export default {
	name: 'StudentsList',
	components:{
		Modal, 
		'student': StudentListItem,
		'single-student': NewStudent, 
        'students-batch': UploadStudentsBatch,
		ValidationHelper, 
		Tab
	},
	data(){
		return{
			editMode: false, 
			originalName: '', 
			showAddStudentModal: false,  
			pages: [
				{name: 'Single student', component: 'single-student'}, 
				{name: 'Multiple students', component: 'students-batch'}
			], 
			selectedPage: undefined
		}
	}, 
	computed:{
		classroom(){
			return this.$store.state.selectedClassroom
		}
	}, 
	methods:{
		tabChanged(e){
			this.selectedPage = e
		}, 
		closeModal(){
			this.showAddStudentModal = false 
		}
	},
	mounted() {
		this.originalName = this.classroom.name
	},
}
</script>
<style lang="less" scoped>

.students-list{
	min-height: 196px;
	box-sizing: border-box;
	padding: 16px;
	button{
		margin: 16px 0;
		margin-left: auto;
	}
	.no-content{
		min-height: 300px;
		align-items: center;
		justify-content: center;
	}
	.tab{
		box-shadow: none;
	}
}
</style>